// setup
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});


// site.js

$.site = {};

$.site.navigation = {
    init: function() {
        $(".hamburger").on("click", function(e) {
            e.preventDefault();
            $('.navbar .dropdown-menu').addClass('mobile-menu');
            $(".navbar").toggleClass("open");
        });
        $(".close-nav").on("click", function(e) {
            e.preventDefault();
            $('.navbar .dropdown-menu').removeClass('mobile-menu');
            $(".navbar").toggleClass("open");
        });
    },
};

$.site.cart = {
    activate: function() {
        $(document).on('change', '.quantity', function() {
            let id = $(this).parent().parent().attr('data-id');
            let q = $(this).val();
            $.site.cart.updateItem(id, q);
        });
    },
    updateItem: function(id, q) {
        let url = `/cart/update/${id}/${q}`;
        $.post(url, null, null, "script");
        // $.notify({
        //   title: "Item Updated",
        //   message: "Item has been updated."},{
        //   delay: 3000
        // });
        $.site.cart.getCartTotal();
    },
    getCartTotal: function() {
        $.get("/cart.json", null, function(data) {
            $('.cart-item-total').html(data.total_items);
            $('.cart-link').attr('title', "Cart: " + data.total_items);
        });
    },
};


$.site.product = {
    pageSize: function() {
        $(".page_size").on("change", function() {
            let s = new URLSearchParams(location.search);
            let url = location.href.split("?")[0];
            url += "?page=1&per_page=" + $(this).val();
            if (s.has("filters")) {
                url += "&filters=" + s.get("filters");
            }
            location.href = url;
        });
    },
    addToCart: function(e) {
        $("[data-action=addToCart]").on('click', function(e) {
            e.preventDefault();
            let json = JSON.parse($(this).attr('data'));
            let qty = 1;

            if ($('.qty-block .qty').length == 1) {
                qty = Number.parseInt($('.qty-block .qty').val());
            }

            // console.log(json);
            if (json.action === "add") {
                $.post("/cart/add", { qty: qty, p: json.id }, function(data) {
                    $('.cart-total').html("Cart (" + data.total_cart + ")");
                    $('.product-cart-dialog .product-title').html(data.product_title);
                    $('.product-cart-dialog .product-image').attr('src', data.product_image);
                    $('.product-cart-dialog .product-image').attr('alt', data.product_title);
                    $('.product-cart-dialog').modal('show');
                });
            }
        });
    },


    filters: function() {
        $('.select-product-category-filter').on('change', function(e) {
            var url = $(this).attr('data-url');
            var v = $(this).val();
            location.href = url + '/' + v;
        });
        // $(".filter-item").on("click", function(e) {
        //   e.preventDefault();
        //   $(this).toggleClass("active");
        //   let s = new URLSearchParams(location.search);
        //   let list = $(".filter-item.active");
        //   let items = [];
        //   console.log(list);

        //   for (let i = 0; i < list.length; i++) {
        //     let obj = $(list[i]);
        //     items.push({
        //       a: obj.attr("data-name"),
        //       b: obj.attr("data-value"),
        //     });
        //   };
        //   console.log(items);
        //   let perPage = s.get("per_page");
        //   if (perPage === null) {
        //     perPage = 12;
        //   }
        //   location.href = location.href.split("?")[0] + "?page=1&per_page=" + perPage + "&filters=" + JSON.stringify(items);
        // });
    },
    imageGallery: function() {
        $('.product-image-gallery-small a').on('click', function(e) {
            e.preventDefault();
            let idx = $(this).attr('data-index');
            $('.product-image-gallery .image-item').removeClass('active');
            $('.product-image-gallery .image-item[data-index=' + idx + ']').addClass('active');

            $('.product-image-gallery-small a .image-item').removeClass('active');
            $('.product-image-gallery-small a[data-index=' + idx + '] .image-item').addClass('active');
        });
    },
    relatedProduct: function() {
        $('.related-product-select').on('change', function(e) {
            location.href = $(this).val();
        });
    }
};

$.site.utility = {
    levelRow: function(row, item) {
        let obj = $(row);
        obj.each(function(k, ob) {
            let max = 0;
            let items = $(ob).find(item);
            items.each(function(i, o) {
                let h = $(o).outerHeight();
                if (h > max) {
                    max = h;
                }
            });
            items.css("height", max + "px");
        });
    },
};

$.site.autocomplete = null;
$.site.autocompleteS = null;
$.site.checkout = {
    address: function() {
        const input = document.getElementById('billingAddressPac');
        const inputS = document.getElementById('shippingAddressPac');
        const options = { componentRestrictions: { country: 'us' }, fields: ["address_components", "geometry", "icon", "name"], strictBounds: false };

        $.site.autocomplete = new google.maps.places.Autocomplete(input, options);
        $.site.autocomplete.setFields(["place_id", "geometry", "name"]);
        $.site.autocomplete.addListener("place_changed", $.site.checkout.billingAddressComplete);

        $.site.autocompleteS = new google.maps.places.Autocomplete(inputS, options);
        $.site.autocompleteS.setFields(["place_id", "geometry", "name"]);
        $.site.autocompleteS.addListener("place_changed", $.site.checkout.shippingAddressComplete);

        $('#billingAddressTwo').on('blur', function(e) {
            $('#order_billing_address_attributes_address_two').val($(this).val());
        });
        $('#shippingAddressTwo').on('blur', function(e) {
            $('#order_shipping_address_attributes_address_two').val($(this).val());
        });

        $('#sameAddress').on('click', function(e) {
            if ($(this).is(":checked")) {
                $("#shippingAddressCtrl").hide();
                $("#shippingAddressPac").val('');
                const addressObjects = new Array("address", "address_two", "city", "state", "zip", "country");
                for (let i = 0; i < addressObjects.length; i++) {
                    $('#order_shipping_address_attributes_' + addressObjects[i]).val($('#order_billing_address_attributes_' + addressObjects[i]).val());
                }
            } else {
                $('#shippingAddressCtrl').show();
                $('#order_shipping_address_attributes_address').val('');
                $('#order_shipping_address_attributes_address_two').val('');
                $('#order_shipping_address_attributes_city').val('');
                $('#order_shipping_address_attributes_state').val('');
                $('#order_shipping_address_attributes_zip').val('');
                $('#order_shipping_address_attributes_country').val('');
            }
        });

        if ($('#sameAddress').is(":checked")) {
            $('#shippingAddressCtrl').hide();
        }
    },
    billingAddressComplete: function() {
        const place = $.site.autocomplete.getPlace();
        if (place === null) { return null; }
        $.site.checkout.fillInAddress("billing", place);
    },
    shippingAddressComplete: function() {
        const place = $.site.autocompleteS.getPlace();
        if (place === null) { return null; }
        $.site.checkout.fillInAddress("shipping", place);
    },
    fillInAddress: function(a, place) {
        if (place === null || place === undefined) { return null; }

        console.log(place.address_components);
        let address1 = "";
        let postalCode = "";
        let city = "";
        let state = "";
        let country = "";

        for (let component of place.address_components) {
            let componentType = component.types[0];

            switch (componentType) {
                case "street_number":
                    {
                        address1 = `${component.long_name} ${address1}`;
                        break;
                    }
                case "route":
                    {
                        address1 += component.short_name;
                        break;
                    }
                case "postal_code":
                    {
                        postalCode = `${component.long_name}${postalCode}`;
                        break;
                    }
                case "locality":
                    {
                        city = component.short_name;
                        break;
                    }
                case "administrative_area_level_1":
                    {
                        state = component.short_name;
                        break;
                    }
                case "country":
                    {
                        country = component.short_name;
                        break;
                    }
            }
            $('#order_' + a + '_address_attributes_address').val(address1);
            $('#order_' + a + '_address_attributes_city').val(city);
            $('#order_' + a + '_address_attributes_state').val(state);
            $('#order_' + a + '_address_attributes_zip').val(postalCode);
            $('#order_' + a + '_address_attributes_country').val(country);
        }
    },
};
$.site.search = {
    activate: function() {
        $('#searchBar form').on('submit', function(e) {
            e.preventDefault();
            let searchTerm = $('#searchBar input[type=search]').val();
            if (searchTerm !== "") {
                location.href = "/shop/search/" + searchTerm;
            }
        });

        $('.search-link').on('click', function(e) {
            e.preventDefault();
            $('#searchBar').slideToggle('fast', function() {
                var bar = document.getElementById('searchBar');
                var height = bar.offsetHeight;
                var topNav = document.querySelector('.topbar-links');
                var t = 80;
                if (topNav.offsetTop >= 80) {
                    if (height > 0) {
                        t = t + height;
                    }
                    topNav.style.top = t + "px";
                }
            });
        });
    },
}

$.site.form = {
    submit: function(buttonCtrl) {
        $(buttonCtrl).on('click', function(e) {
            e.preventDefault();
            $(this).closest('form').submit();
        });
    },
}

$.site.testimonial = {
    init: function() {
        $('.testimonial-dots a').on('click', function(e) {
            e.preventDefault();
            let idx = $(this).attr('data-index');
            $('.testimonial-dots a').removeClass('active');
            $('.testimonial-dots a[data-index=' + idx + ']').addClass('active');
            $('.testimonial-item').removeClass('active');
            $('.testimonial-item[data-index=' + idx + ']').addClass('active');

        })
    }
}

$.site.tickerIndex = 0;
$.site.tickerCount = 0;
$.site.ticker = {
    init: function() {
        $.site.tickerCount = $('.ticker .ticker-item').length;
        $('.ticker .ticker-item[index=' + $.site.tickerIndex + ']').addClass('active');
        setInterval(function() {
            $.site.ticker.start();
        }, 4000);
    },
    start: function() {
        $.site.tickerIndex++;
        if ($.site.tickerIndex >= $.site.tickerCount) {
            $.site.tickerIndex = 0;
        }
        $('.ticker .ticker-item').removeClass('active');
        $('.ticker .ticker-item[index=' + $.site.tickerIndex + ']').addClass('active');
    }
}

$(function() {
    $.site.navigation.init();
    $.site.product.pageSize();
    $.site.cart.getCartTotal();
    $.site.search.activate();
    $.site.form.submit('.submit-button');
    $.site.ticker.init();
});